import React from "react"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#202020",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  container: {
    // background: 'none transparent!important',
    display: "flex",
    alignSelf: "flex-start",
    overflow: "hidden",
    // width: '300px',
    height: "370px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    // transform: 'translateZ(0px)',
    // border: '3px solid #eee',
    // background: 'none transparent',
    background: "#666B65",
  },
}))

const Iframe = ({ source }) => {
  const classes = useStyles()

  if (!source) {
    return <div>Loading...</div>
  }

  const src = source
  return (
    <div className={classes.container}>
      <iframe src={src} allowtransparency="true" frameBorder="0"></iframe>
    </div>
  )
}

export default Iframe
