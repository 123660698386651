import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import MainToolbar from "../components/main-toolbar"
// import backgroundImage from "../images/amsterdamFilteredB32Hue.jpg"
import backgroundImage from "../images/clinic-Bussum.jpg"
import LineTitle from "../components/LineTitle"
import ClinicCard from "../components/Cards/ClinicCard"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Iframe from "../components/Iframe"
import Box from "@material-ui/core/Box"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  background: {
    padding: 0,
    background: `#333 url(${backgroundImage}) 50% 0 no-repeat`,
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  contactForm: {
    marginBottom: "-8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "60px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  cardInnerContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ["@media (max-width:780px)"]: {
      width: "90%",
    },
  },
  titleBig: {
    paddingTop: "3em",
    paddingBottom: "2em",
    fontWeight: "400",
    color: "#9D989A",
  },
  tooLongtitle: {
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  contactTitle: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "170px",
    // paddingBottom: '8px',
  },
  contactSub: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "10px",
    paddingBottom: "20px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  titleSpan: {
    backgroundColor: "#666B65",
    paddingTop: "12px",
    paddingBottom: "4px",
    paddingLeft: "16px",
    paddingRight: "14px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    width: 300,
  },
  titleSpanEngl: {
    paddingLeft: "31px",
    paddingRight: "31px",
  },
}))

const Contact = ({ intl, location }) => {
  const classes = useStyles()
  const form = "https://kooldigital.lpages.co/test-form/"
  // Translate function made shorter..
  const t = (id) => intl.formatMessage({ id })

  console.log("intl", intl.locale)
  console.log("location", location)

  const isNL = true ? intl.locale === "nl" : false

  console.log("INTER", isNL)

  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "about.SEOtitle" })}
        description={intl.formatMessage({ id: "about.SEO" })}
        path={location.pathname}
      />
      <MainToolbar className={classes.toolbar} />
      <Container maxWidth={"xl"} className={classes.background}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Container>
              <LineTitle
                header={t("contact.header")}
                subheader={t("contact.subheader")}
                titleStyle={classes.tooLongtitle}
                subHeaderStyle={classes.tooLongSubheader}
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h5"
              variant="h5"
              align="center"
              className={classes.contactTitle}
            >
              <Box fontWeight="fontWeightLight">
                <span
                  className={`${classes.titleSpan} ${
                    !isNL ? classes.titleSpanEngl : ""
                  }`}
                >
                  {t("contact.request")}
                </span>
              </Box>
            </Typography>
            {/* <Typography component="p" variant="p" align="center" className={classes.contactSub}>
                  <Box fontWeight="fontWeightRegular">
                    Get in touch with our cosmetic doctors
                  </Box>
                </Typography> */}
            <div className={classes.contactForm}>
              <Iframe source={form} />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={"xl"} className={classes.cardContainer}>
        <Container className={classes.cardInnerContainer}>
          <Typography
            component="h3"
            variant="h4"
            align="center"
            className={classes.titleBig}
          >
            {t("contact.title")}
          </Typography>
          <ClinicCard
            city={t("contact.cards.amsterdam.city")}
            text={t("contact.cards.amsterdam.text")}
            hashtags=""
            address={[
              "Frederiksplein 47B",
              <br />,
              "1017 XL Amsterdam",
              <br />,
              "Tel: +31 (0) 85 029 0829",
            ]}
            borderLeft="8px solid #9AA49A"
            // backgroundColor='#5B5B5B'
            backgroundColor="#666B65"
            titleColor="white"
            addressColor="white"
          />
          <ClinicCard
            city={t("contact.cards.bergen.city")}
            text={t("contact.cards.bergen.text")}
            hashtags=""
            address={[
              "Dokter van Peltlaan 5",
              <br />,
              "1861 KA Bergen",
              <br />,
              "Tel: +31 (0) 85 029 0829",
            ]}
            borderLeft="8px solid #BFC7BC"
            backgroundColor="#5B5B5B"
            titleColor="white"
            addressColor="white"
          />
          {/* <ClinicCard
              city={t("contact.cards.beverwijk.city")}
              text={t("contact.cards.beverwijk.text")}
              hashtags='#botox #injectables #scin-care'
              address={['Breestraat 101', <br/>,
              '1941 EG Beverwijk', <br/>,
              'Tel: +31 (0) 85 773 1993']}
              borderLeft='8px solid #E4F2DF'
              backgroundColor='#828382'
              titleColor='white'
              addressColor='white'
            /> */}
          <ClinicCard
            city={t("contact.cards.bussum.city")}
            text={t("contact.cards.bussum.text")}
            hashtags=""
            address={[
              "Parklaan 2",
              <br />,
              "1406 KL Bussum",
              <br />,
              "Tel: +31 (0) 85 029 0829",
            ]}
            borderLeft="8px solid #9AA49A"
            backgroundColor="#666B65"
            titleColor="white"
            addressColor="white"
          />
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(Contact)

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
